import React from 'react';
import { useQuery, gql } from '@apollo/client';

// Define the GraphQL query
const GET_CATEGORIES = gql`
  query MyQuery {
    categories {
      edges {
        node {
          id
          name
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

function Categories() {
  // Use the useQuery hook to fetch categories
  const { loading, error, data } = useQuery(GET_CATEGORIES);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div>
      <h1>Categories</h1>
      <ul>
        {data.categories.edges.map(({ node }) => (
          <li key={node.id}>{node.name}</li>
        ))}
      </ul>
      <div>
        <p>Page Information:</p>
        <p>Start Cursor: {data.categories.pageInfo.startCursor}</p>
        <p>End Cursor: {data.categories.pageInfo.endCursor}</p>
        <p>Has Next Page: {data.categories.pageInfo.hasNextPage ? 'Yes' : 'No'}</p>
        <p>Has Previous Page: {data.categories.pageInfo.hasPreviousPage ? 'Yes' : 'No'}</p>
      </div>
    </div>
  );
}

export default Categories;
