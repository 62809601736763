import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

// HTTP link to your GraphQL endpoint
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

// Set the Authorization header for every request
const authLink = setContext((_, { headers }) => {
  // Get the token from the .env file for development
  const token = process.env.REACT_APP_STATIC_TOKEN;
  console.log(token);

  return {
    headers: {
      ...headers,
      Authorization: token
    },
  };
});

// Apollo Client setup with auth link and HTTP link
const client = new ApolloClient({
  link: authLink.concat(httpLink), // Combine the authLink and httpLink
  cache: new InMemoryCache(),
});

export default client;
